<template>
  <sb-app-page
    class="sb-page"
    :key="$route.params.pageId"
    :company-id="companyId"
    :page-id="pageId"
    read-only
  ></sb-app-page>
</template>
<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["companyId"]),
    pageId() {
      return this.$route.params.pageId;
    },
  },
  mounted() {
    this.$store.commit("layout/TOGGLE_IS_FOOTER_VISIBLE", false);
  },
  beforeDestroy() {
    this.$store.commit("layout/TOGGLE_IS_FOOTER_VISIBLE", true);
  },
};
</script>

